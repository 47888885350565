import React from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';


export default function screenshotDescription(props) {
    var cardWidth = 250;
    return (
    <div class='screenshotDescription'>
        <div class='screenshot-preview'>
                <Card sx={{ width: cardWidth }} variant='outlined'>
                    <CardMedia
                        sx={{ height: cardWidth * 2, width: cardWidth }}
                        image={props.image}
                    />
                    <CardContent>
                        <Typography gutterBottom variant="body2" component="div" fontFamily={'Unbounded'} letterSpacing={2}>
                            {props.title}
                        </Typography>
                        <Typography variant="body3" color="text.secondary" fontFamily={'Unbounded'} letterSpacing={1.5}>
                            {props.description}
                        </Typography>
                    </CardContent>
                </Card>
        </div>
    </div>
    )
}