import { Grid } from '@mui/material'
import React from 'react'

export default function featuresPage() {
    return (
        <div className='rightSide'>
            <div className='features'>
                <Grid container>
                    <Grid item xs={12} lg={6}>
                        <div className='featuresText'>
                            <div class='featureListHeader'>Key Features</div>
                            <ul>
                                <li><div class='featurePoint'>Weather Forecasting:</div> Leverage the data-driven predictions of the SMHI (Swedish Meteorological and Hydrological Institute) model for accurate and reliable weather forecasting. Stay prepared for your next kitesurfing session with these real-time forecasts.</li>
                                <li><div class='featurePoint'>Live Wind Data:</div> Receive live wind data directly from weather stations located along the Baltic Sea coast in Poland. Stay informed about the current wind conditions to plan your kiteboarding sessions better.</li>
                                <li><div class='featurePoint'>Wind Visualization:</div> Utilize the wind visualization feature to better understand the wind directions and their intensities. This interactive tool will help you visualize the wind patterns for effective session planning.</li>
                                <li><div class='featurePoint'>Session Planning:</div> Plan your sessions ahead of time. With this feature, not only can you view the weather forecast for your chosen time but you can also see who else will be surfing at your spot. Each session comes with a history of weather data, providing insights into your past sessions.</li>
                                <li><div class='featurePoint'>Chat at Spots:</div> Connect and communicate with other users through spot-specific chatrooms. Ask about the current conditions, crowd levels, and anything else you need to know.</li>
                                <li><div class='featurePoint'>Live Session Tracking:</div> Show your presence at a surfing spot by sliding the "ON SPOT" button. This way, other users can know that you're currently kitesurfing. You can also revisit your own reports to remember when and under what conditions you surfed.</li>
                                <li><div class='featurePoint'>Favourite Spot Feature:</div> Add your favourite spots and get notified if many people are planning sessions there, someone posts a message in the chat, or if the weather stations record ideal wind direction and strength. Be ready to pack up your gear and go surfing spontaneously when conditions are just right.</li>
                                <li><div class='featurePoint'>Session History:</div> View your past sessions along with the prevailing conditions, and see who else was kitesurfing at the time. Reflect on your experiences to enhance future sessions.</li>
                                <li><div class='featurePoint'>Spot Map:</div> A live view map featuring all spots at once for a comprehensive glance at all your options.</li>
                            </ul>
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <div className='featuresText'>
                            <div class='featureListHeader'>Future Enhancements</div>
                            <ul>
                                <li><div class='featurePoint'>Private Chat:</div> Facilitating one-on-one conversations between users.</li>
                                <li><div class='featurePoint'>Lost & Found Section:</div> A space to report items you've left behind or found at a spot.</li>
                                <li><div class='featurePoint'>Information on Schools and Course Prices:</div> Details about kitesurfing schools and their course prices as well as private offers from kitesurfing instructors. Stay informed about learning opportunities and private tutoring offers.</li>
                            </ul>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}
