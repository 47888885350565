import React, { Component } from 'react';
import './styles/rightSide.css'
import LandingPage from './landingPage';
import PrivacyPage from './privacyPage';
import Support from './supportPage'
import TermsAndConditions from './termsAndConditions';
import FeaturesPage from './featuresPage';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

class RightSide extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            isLoaded: false,
            items: []
        }
    }

    render() {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path='/' element={<LandingPage/>}/>
                    <Route path='/features' element={<FeaturesPage/>}/>
                    <Route path='/privacy' element={<PrivacyPage/>}/>
                    <Route path='/terms' element={<TermsAndConditions/>}/>
                    <Route path='/support' element={<Support/>}></Route>
                </Routes>
            </BrowserRouter>
        );
    }
}
 
export default RightSide;