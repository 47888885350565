import React from 'react'
import appStoreImg from './../images/appstore.png'
import googlePlayImg from './../images/googleplay.png'
import logo from './../images/logo.png'
import { Stack } from '@mui/material'

export default function LandingPage() {
  return (
    <>
      <div className='rightSide'>
          <div className='informations'>
              <Stack spacing={2}>
                  <div className='logoContainer'>
                      <img className='logo' src={logo} alt="logo" />
                  </div>
                  <div className='leftTitle'>
                      WINDSPOT APP
                  </div>
                      <div className='appStoreGooglePlay'>
                          <Stack direction='row' spacing={5}>
                              <img src={appStoreImg} alt='googlePlay' />
                              <img src={googlePlayImg} alt='appStore' />
                          </Stack>
                      </div>
                  <div className='informationsText'>
                      Experience the thrill of kitesurfing like never before with our new mobile application, now accessible on both iOS and Android platforms.<br /><br />
                      Venture into the heart of the Baltic Sea with real-time wind data and weather conditions from numerous spots. This live information at your fingertips empowers you to plan your sessions accurately and maximize your kitesurfing experience.<br /><br />
                      Our application offers an interactive platform to log your sessions. Discover fellow surfers in your vicinity and seize the opportunity to coordinate, learn from each other, and elevate your kitesurfing skills.<br /><br />
                      Embrace the social aspect of kitesurfing. Connect with like-minded enthusiasts, embark on exciting downwinders, and keep tabs on live sessions around you. Have queries about crowded or serene spots? Just ask within our app's friendly community.<br /><br />
                      <br /><br />
                      Join us and make your kitesurfing journey more dynamic, engaging, and fun!
                  </div>
                  
              </Stack>
          </div>
      </div>
      </>
  )
}
