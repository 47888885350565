import { Divider } from '@mui/material';
import { Stack } from '@mui/system';
import React, { Component } from 'react';
import AirIcon from '@mui/icons-material/Air';
import './styles/windData.css'


class WindData extends Component {
    state = {  }
    render() { 
        return ( 
            <div className='windData'>
                <Stack textAlign='center'>                                   
                    <Stack textAlign='center' alignItems='center' justifyContent='center' spacing={3} direction='row'>
                        <div className='windSpeed'>{Math.floor(this.props.currentWind * 10)/10} kts. </div>
                        <AirIcon/>
                    </Stack>
                    <Divider className='windDataDivider'/>
                    <div className='spotName'>
                        {this.props.spotName}
                    </div>     
                </Stack>
            </div>
         );
    }
}
 
export default WindData;