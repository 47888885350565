import './App.css';
import Content from './components/content';
import React from 'react'
import { ThemeProvider, createTheme } from '@mui/material/styles';

function App() {
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  return (
    <div className="App">
      <ThemeProvider theme={darkTheme}>
        <Content/>
      </ThemeProvider> 
    </div>
  );
}

export default App;
