import React from 'react'
import ScreenshotDescription from './screenshotDescription'
import homepage from './../images/screenshots/homepage.png'
import weatherDetails from './../images/screenshots/weatherdetails.png'
import weatherDetails2 from './../images/screenshots/weatherdetails2.png'
import weatherMap from './../images/screenshots/weathermap.png'
import weatherMap2 from './../images/screenshots/weathermap2.png'
import bookSession from './../images/screenshots/booksession.png'
import chat from './../images/screenshots/chat.png'
import chatList from './../images/screenshots/chatslist.png'
import profileSettings from './../images/screenshots/profilesettings.png'
import sessionOn from './../images/screenshots/sessionon.png'
import userSessionsPast from './../images/screenshots/usersessions_past.png'
import userSessionsUpcoming from './../images/screenshots/usersessions_upcoming.png'

import { Grid } from '@mui/material'

export default function supportPage() {
    var gridSpacingXs = 10;
    var gridSpacingLg = 3;

    return (
    <div class='rightSide'>
        <div class='support'>
            <Grid container justifyContent='center' spacing={2}>
                <Grid item xs={gridSpacingXs} lg={gridSpacingLg}>
                    <ScreenshotDescription
                        image={homepage}
                        title='Home Page'
                            description='Display a list of available spots, each showcasing average wind, min and max wind, 
                            and scrollable weather. Clicking on a spot navigates you to a detailed weather view.'/>
                </Grid>
                    <Grid item xs={gridSpacingXs} lg={gridSpacingLg}>
                    <ScreenshotDescription
                        image={weatherDetails}
                        title='Weather Details'
                            description='Dive into detailed data about the selected spot. Beyond current wind data, explore forecasts 
                            (leveraging the SMHI weather forecast model), preview recent chat messages, and view sessions booked by users.'/>
                </Grid>
                    <Grid item xs={gridSpacingXs} lg={gridSpacingLg}>
                    <ScreenshotDescription
                        image={weatherDetails2}
                        title='Weather Details Chart'
                            description='Features a wind graph depicting the last 12 hours of wind data. The social widget displays the last 5 messages, 
                            while the sessions widget lists the upcoming 5 sessions booked on the spot by users.'/>
                </Grid>
                    <Grid item xs={gridSpacingXs} lg={gridSpacingLg}>
                    <ScreenshotDescription
                        image={weatherMap}
                        title='Weather Map'
                        description='Reflects spots on the map, with icons displaying the current average wind (from the last 5 minutes).'/>
                </Grid>
                    <Grid item xs={gridSpacingXs} lg={gridSpacingLg}>
                    <ScreenshotDescription
                        image={weatherMap2}
                        title='Weather Map Zoomed'
                            description='Tapping on a spot zooms into the location, presenting a weather details widget with upcoming 
                            forecasts and current wind readings.' />
                </Grid>
                    <Grid item xs={gridSpacingXs} lg={gridSpacingLg}>
                    <ScreenshotDescription
                        image={bookSession}
                        title='Book Session on Spot'
                            description='Two methods to book a session if the forecast looks promising: 1) Click on a day within the forecast 
                            widget that appeals to you - the data will pre-fill for the selected day. 2) Click the white hamburger icon in the 
                            bottom right corner, and select the kitesurfer icon.'/>
                </Grid>
                    <Grid item xs={gridSpacingXs} lg={gridSpacingLg}>
                    <ScreenshotDescription
                        image={chat}
                        title='Spot Chat'
                            description='Connect with others monitoring the wind. Clicking the bell icon in the top right corner enables 
                            notifications for new messages.'/>
                </Grid>
                    <Grid item xs={gridSpacingXs} lg={gridSpacingLg}>
                    <ScreenshotDescription
                        image={chatList}
                        title='Chat List'
                            description='A straightforward list of recent messages from all spots. In the future, this will also host 
                            a list of private chats among users.'/>
                </Grid>
                    <Grid item xs={gridSpacingXs} lg={gridSpacingLg}>
                    <ScreenshotDescription
                        image={profileSettings}
                        title='Profile'
                            description='Update your nickname or profile picture, or remove existing ones. Access application settings 
                            and navigate to the blocked users list, where you can unblock users if needed.'/>
                </Grid>
                    <Grid item xs={gridSpacingXs} lg={gridSpacingLg}>
                    <ScreenshotDescription
                        image={sessionOn}
                        title='Live Session'
                        description='Notify others of your presence on a spot by toggling the switch in the top right corner. 
                        This records and displays your session, allowing others to see you. It also logs your session for future 
                        reference in the User Sessions area, showing wind charts and a list of other users present at that time.'/>
                </Grid>

                    <Grid item xs={gridSpacingXs} lg={gridSpacingLg}>
                    <ScreenshotDescription
                        image={userSessionsPast}
                        title='Past Sessions'
                            description='A record of your booked or live sessions, with wind data and a list of attendees, 
                            facilitating post-session comparisons.'/>
                </Grid>
                    <Grid item xs={gridSpacingXs} lg={gridSpacingLg}>
                    <ScreenshotDescription
                        image={userSessionsUpcoming}
                        title='Upcoming Sessions'
                            description='A list of sessions you have booked, displaying forecasts for the selected times and 
                            other users who will be at the spot with you.'/>
                </Grid>
            </Grid>
        </div>
    </div>)
}