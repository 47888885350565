import './styles/header.css';
import React from 'react';
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { Divider, Button, Box, Link } from '@mui/material';

export default function Header() {
    const pages = ['download', 'features', 'support', 'privacy', 'terms', 'contact'];

    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <AppBar className='appbar' sx={
            {
                background: 'linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 45%, rgba(0, 0, 0, 0.6) 100%)',
                height: '45px'
            }}>
            <Toolbar sx={{ marginLeft: '20px', minHeight: { md: '45px', xs: '45px' } }}>

                <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenNavMenu}
                        color="inherit">
                        <MenuIcon />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                        sx={{
                            display: { xs: 'block', md: 'none' },
                        }}>
                        {pages.map((page) => {
                            var path = page;
                            if (page == 'download') {
                                path = '/';
                            }
                            return (

                                <MenuItem component={"a"} key={page} onClick={handleCloseNavMenu} href={path}>
                                    <Typography textAlign="center" fontFamily={'Unbounded'}>{page}</Typography>
                                </MenuItem>
                            );
                        })}
                    </Menu>
                </Box>

                <div className='headerTitle' href='/'>
                    <Button variant='#828282' className='menuItem' href='/'>windspot.app</Button>
                </div>
                <div className='headerDividerContainer'>
                    <Divider className='headerDivider' orientation='vertical' />
                </div>
                <div className='headerMenuItems'>
                    <Button variant='#828282' className='menuItem' href='/features'>features</Button>
                    <Button variant='#828282' className='menuItem' href='/support'>support</Button>
                    <Button variant='#828282' className='menuItem' href='/privacy'>privacy</Button>
                    <Button variant='#828282' className='menuItem' href='/terms'>terms and conditions</Button>
                    <Button variant='#828282' className='menuItem' href='/contact'>contact</Button>
                </div>
                
            </Toolbar>
        </AppBar>
    );

}