import React, { useState } from 'react';
import WindData from './windData';
import './styles/windDataContainer.css'
import { useInterval } from '../hooks/useInterval';

export default function WindDataContainer() {
    const [items, setitems] = useState(fetchData);
    
    useInterval(() => {
        fetchData();
    }, 60000);

    function fetchData(){
        try {
        fetch("https://windspot-api.azurewebsites.net/weather/simple", { mode: "cors", referrer: 'origin-when-cross-origin'})
            .then((res) => {
                if (res.status >= 400){
                    return [];
                } else {
                    return res.json();
                }
            })
            .then((result) => {
                setitems(result);
                return result;
            });
        }
        catch (error) {
            console.log(error);
        }
    }
    if (items != null){
        return (
            items.map(w =>
                    <WindData
                        key={w.id} 
                        spotName={w.name} 
                        currentWind={w.currentWind}/>
                )
        );   
    }
}