import React, { Component } from 'react';
import './styles/leftSide.css'
import WindDataContainer from './windDataContainer';

class LeftSide extends Component {
    state = {  } 
    render() { 
        return (
            <div className="leftContent">
                <div className='windDataContainer'>
                    <WindDataContainer className='windDataContainer'/>
                </div>
            </div>
        );
    }
}
 
export default LeftSide;