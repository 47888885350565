import { Grid } from '@mui/material';
import React, { Component } from 'react';
import RightSide from './rightSide';
import LeftSide from './leftSide';
import Header from './header'
import './styles/content.css'

class Content extends Component {
    render() {
        return (
            <div className='contentGrid'>
                <Header />
                <Grid container spacing={0} margin={0}>
                    <Grid item={true} xs={0} md={4}>
                        <LeftSide />
                    </Grid>
                    <Grid item={true} xs={12} md={8}>
                        <RightSide xs={{ display: { sm: 'none' } }} />
                    </Grid>
                </Grid>
                <div className='linearGrey' />
            </div>
        );
    }
}

export default Content;